<template>
  <div class="page">
    <div class="content-box section">
      <easy-card title="套餐基本信息">
        <icon-button slot="extra" icon="return" @click.native="goBack"/>

        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 套餐类型</template>
            单车场
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 套餐名称</template>
            {{ dataList.leaseTitle }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 适用车场</template>
            {{ dataList.parkingName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 结算方名称</template>
            {{ dataList.paySettleAccountName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 长租周期</template>
            {{ dataList.longLeasePeriod }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 有效时段</template>
            周一至周日 <span v-if="dataList.effectualTime === '全天'">全天</span> <span
              v-if="dataList.effectualTime === '分时段'"> {{ dataList.beginTime }}~{{ dataList.endTime }}</span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 支持购买方式</template>
            {{ dataList.buyWay }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 支持购买车牌</template>
            {{ dataList.licensePlateColor }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 状态</template>
            {{ dataList.leaseStatus }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 套餐价格</template>
            {{ (parseFloat(dataList.leasePrice)).toFixed(2) }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 支持线上展示</template>
            {{ dataList.onlineShow }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 多车场内继承</template>
            禁止
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 套餐说明</template>
            {{ dataList.leaseRemark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建人</template>
            {{ dataList.creator }}
          </el-descriptions-item
          >
          <el-descriptions-item>
            <template slot="label"> 创建时间</template>
            {{ dataList.createTime }}
          </el-descriptions-item>
        </el-descriptions>
      </easy-card>

      <div class="separate-line"></div>

      <easy-card title="关联长租订单">
        <div class="search-box section">
          <el-form
              ref="searchForm"
              :inline="true"
              :model="query"
              class="demo-form-inline"
              size="medium"
          >
            <el-form-item prop="orderCode">
              <el-input
                  v-model="query.orderCode"
                  placeholder="请输入长租订单编号"
                  style="width: 310px"
              ></el-input>
            </el-form-item>
            <el-form-item prop="autoId">
              <!-- <el-input
                v-model="query.autoId"
                placeholder="请输入车牌"
              ></el-input> -->
              <NumplateSuggest v-model="query.autoId" style="width: 260px"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="goSearch">搜索</el-button>
              <el-button @click="resetForm">重置</el-button>
              <el-button type="success" @click="uploadDialogFormVisible = true"
              >批量上传
              </el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <div class="op-box">
          <div class="op-content">
            共计<span class="total-size">{{ total }}</span>条信息
          </div>
        </div>

        <div>
          <el-table
              v-loading="isLoading"
              :data="tableData"
              border
              class="common-table"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="长租订单编号" prop="orderCode">
            </el-table-column>
            <el-table-column label="车牌号" prop="autoId" width="150">
              <template slot-scope="scope">
                <!-- <div :class="['car-number',scope.row.autoColor === '01' ? 'autoColor-yellow' : scope.row.autoColor === '02' ? 'autoColor-blue' : scope.row.autoColor === '03' || scope.row.autoColor === '04' ? 'autoColor-green' : 'autoColor-black']">
                  {{scope.row.autoId}}
                </div> -->
                <Numplate :type="scope.row.autoColor">
                  {{ scope.row.autoId }}
                </Numplate>
              </template>
            </el-table-column>
            <el-table-column
                label="长租生效/到期"
                prop="beginDate,endDate"
                width="250"
            >
              <template slot-scope="scope">
                <div>生效:{{ scope.row.beginDate }}</div>
                <div>到期:{{ scope.row.endDate }}</div>
              </template>
            </el-table-column>
            <el-table-column label="添加途径" prop="addWay" width="200">
              <template slot-scope="scope">
                {{ scope.row.addWay === 0 ? '人工添加' : scope.row.addWay === 1 ? '小程序添加' : '其他' }}
              </template>
            </el-table-column>
            <el-table-column label="购买数量" prop="buyNumber" width="190">
            </el-table-column>
            <el-table-column label="订单金额(元)" prop="orderPrice" width="190">
              <template slot-scope="scope">
                {{ (parseFloat(scope.row.orderPrice)).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="付款状态" prop="payStatus" width="190">
              <template slot-scope="scope">
                {{ scope.row.orderStatus === 0 ? '已付款' : "未付款" }}
              </template>
            </el-table-column>
            <el-table-column label="订单状态" prop="orderStatus" width="190">
              <template slot-scope="scope">
                {{ scope.row.orderStatus === 0 ? '正常' : scope.row.orderStatus === 1 ? '关闭' : "手动关闭" }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="100px">
              <template slot-scope="scope">
                <el-button
                    v-if="scope.row.auditStatus === 1"
                    size="small"
                    style="font-size: 14px"
                    type="text"
                    @click="(dialogFormVisible = true), goVerify(scope.row)"
                >审核
                </el-button
                >
                <el-button
                    v-else
                    size="small"
                    style="font-size: 14px"
                    type="text"
                    @click="goDetail(scope.row)"
                >详情
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-box">
            <el-pagination
                :current-page="1"
                :page-size="15"
                :page-sizes="[10, 15, 30, 50]"
                :total="total"
                background
                layout="sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </easy-card>

      <!-- 审核弹出的表单 -->
      <el-dialog
          :append-to-body="true"
          :visible.sync="dialogFormVisible"
          class="common-dialog"
          title="长租订单审核"
      >
        <el-descriptions :column="2" border class="margin-top">
          <el-descriptions-item>
            <template slot="label"> 长租订单编号</template>
            {{ examineList.orderCode }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 结算方名称</template>
            {{ examineList.paySettleAccountName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车场名称</template>
            {{ examineList.parkingName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 套餐名称</template>
            {{ examineList.leaseTitle }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 主车牌</template>
            {{ examineList.autoId }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 副车牌</template>

          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 生效时间</template>
            {{ examineList.beginDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 到期时间</template>
            {{ examineList.endDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 有效时段</template>
            周一至周日{{ examineList.effectualTime === 0 ? "全天" : "" }}
            <span v-if="examineList.effectualTime === 1">
            {{ examineList.beginTime }}~{{ examineList.endTime }}
            </span>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 添加途径</template>
            {{ examineList.addWay }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 购买数量</template>
            {{ examineList.buyNumber }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单金额</template>
            {{ examineList.orderPrice }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 付款状态</template>
            {{ examineList.payStatus }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 订单状态</template>
            {{ examineList.orderStatus === 0 ? '正常' : '异常' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 执行状态</template>
            {{ examineList.implementStatus }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 联系人</template>
            {{ examineList.contactName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 联系电话</template>
            {{ examineList.contactTel }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 所属单位</template>
            {{ examineList.contactCompany }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 泊位号</template>
            {{ examineList.berthNo }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 备注</template>
            {{ examineList.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建人</template>
            {{ examineList.creater }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 创建时间</template>
            {{ examineList.createTime }}
          </el-descriptions-item>
        </el-descriptions>
        <div slot="footer" class="dialog-footer">
          <div>
            <el-input
                v-model="refuseReason"
                class="reason"
                placeholder="请输入-拒绝原因"
            ></el-input>
          </div>
          <el-button type="success" @click="leaseOrderVerify(0)"
          >通过
          </el-button
          >
          <el-button type="danger" @click="leaseOrderVerify(2)">拒绝</el-button>
        </div>
      </el-dialog>

      <!-- 批量上传弹出框 -->
      <el-dialog
          :append-to-body="true"
          :visible.sync="uploadDialogFormVisible"
          class="common-dialog"
          title="批量上传"
      >
        <div class="button-area" style="margin-bottom: 16px; display: flex; flex-direction: row;">
          <el-link :href="downloadUrl" download="长租导入模板.xlsx">
            <el-button style="margin-right: 10px;" type="primary" @click="downLoad">下载模板</el-button>
          </el-link>
          <el-upload
              :before-upload='handleBeforeUpload'
              :http-request="doUpload"
              :show-file-list="false"
              accept=".xlsx"
          >
            <el-button icon="el-icon-upload" type="success">上传文件</el-button>
          </el-upload>
          <div class="total-amount">
            上传总数量：<span style="color:#0768FD;">{{ upLoadList.length }}</span>
          </div>
        </div>
        <div>
          <el-table
              :data="upLoadList"
              border
              class="common-table"
              height="400px"
              size="medium"
              style="width: 100%"
          >
            <el-table-column label="车牌号码" prop="plateNumber">
            </el-table-column>
            <el-table-column label="车牌颜色" prop="colorFriendly">
            </el-table-column>
            <el-table-column
                label="生效时间"
                prop="leaseStartDate"
                width="250"
            >
            </el-table-column>
            <el-table-column label="购买数量" prop="purchaseNum">
            </el-table-column>
            <el-table-column label="联系电话" prop="contactNumber">
            </el-table-column>
            <el-table-column label="联系人" prop="contactPerson">
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="success" @click="save">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  batchImportLeaseList,
  doUploadAnalysisExcel,
  getLeaseInfoById,
  getLeaseOrderById,
  getLeaseOrderListForPage,
  leaseOrderVerify
} from "@/api/lease";
import Numplate from '@/components/Numplate';
import NumplateSuggest from "@/components/NumplateSuggest";

export default {
  name: "lease_detail",
  components: {
    Numplate,
    NumplateSuggest
  },
  data() {
    return {
      downloadUrl: "https://jingli-parking.oss-cn-qingdao.aliyuncs.com/content/lease_template.xlsx",
      isLoading: false,
      id: "", //接收上个页面传过来的套餐id
      parkingLotId: "", //接收上个页面传过来的车场id
      dialogFormVisible: false, //控制审核弹窗层显示
      uploadDialogFormVisible: false, //控制批量上传 弹出层显示
      //获取订单信息供审核
      examineList: {},
      refuseReason: "", //拒绝原因
      verify: "", //审核  0通过    拒绝1
      //获取到的长租套餐信息
      dataList: {},
      //查询条件列表
      query: {
        orderCode: "", //订单编号
        autoId: "", //车牌号
      },
      pageNo: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
      upLoadList: [],
    };
  },
  methods: {
    //下载模板
    downLoad() {
      // window.open( '/src/长租导入模板.xls');
    },
    //上传
    handleBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const isExcel = testmsg === 'xlsx'
      if (!isExcel) {
        this.$message({
          message: '只能上传.xlsx文件',
          type: 'warning'
        })
        return false;
      } else {
        this.doUpload(file);
      }
    },
    async doUpload(file) {

      const formData = new FormData();
      formData.append('file', file.file);
      const res = await doUploadAnalysisExcel(formData);
      if (res && res.code === 30 && res.result) {
        this.upLoadList = res.data.exportList
      }
    },

    //保存模板数据
    save() {
      const params = JSON.stringify({
        parkId: this.parkingLotId,
        // parkId:"6335A517EB9F45A2AB74874F5FC43C76", //测试使用
        leaseInfoId: this.id,
        dataList: this.upLoadList
      })
      console.log(params);
      batchImportLeaseList(params).then(res => {
        if (res.code === 30 && res.result) {
          this.$message.success("保存成功！");
          this.uploadDialogFormVisible = false;
          this.goSearch();
        }
      })
    },
    //取消上传
    cancel() {
      this.uploadDialogFormVisible = false;
    },
    //返回长租套餐页面
    goBack() {
      this.$router.back();
    },
    //查看长租订单详情
    goDetail(e) {
      this.$router.push({
        path: "/lease/order_detail",
        query: {
          id: e.id,
        },
      });
    },
    //重置查询条件
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.goSearch();
    },
    //查询长租套餐详情
    getLeaseInfoById() {
      const id = this.id;
      getLeaseInfoById(id).then((res) => {
        if (res.result === true) {
          const dataList = res.returnObject;
          this.total = res.returnObject.total;
          const longLeasePeriodMap = {
            0: "月",
            1: "季度",
            2: "半年",
            3: "全年"
          }
          dataList.longLeasePeriod = longLeasePeriodMap[dataList.longLeasePeriod]

          const effectualTimeMap = {
            1: "全天",
            0: "分时段"
          }
          dataList.effectualTime = effectualTimeMap[dataList.effectualTime]
          const buyWayMap = {
            0: "开放购买",
            1: "审核购买",
            2: "专享购买",
            3: "不开放购买"
          }
          dataList.buyWay = buyWayMap[dataList.buyWay]

          dataList.licensePlateColor = JSON.parse(dataList.licensePlateColor)
          for (var i = 0; i < dataList.licensePlateColor.length; i++) {
            if (dataList.licensePlateColor[i] === "0") {
              dataList.licensePlateColor[i] = "通用"
            } else if (dataList.licensePlateColor[i] === "01") {
              dataList.licensePlateColor[i] = "黄牌"
            } else if (dataList.licensePlateColor[i] === "02") {
              dataList.licensePlateColor[i] = "蓝牌"
            } else if (dataList.licensePlateColor[i] === "03") {
              dataList.licensePlateColor[i] = "黑牌"
            } else if (dataList.licensePlateColor[i] === "04") {
              dataList.licensePlateColor[i] = "新能源"
            }
          }
          dataList.licensePlateColor = dataList.licensePlateColor.join(' ')
          // const licensePlateColorMap = {
          //     "0": "通用",
          //     "01": "黄牌",
          //     "02": "蓝牌",
          //     "03": "黑牌",
          //     "04": "新能源"
          //   }
          // dataList.licensePlateColor = licensePlateColorMap[dataList.licensePlateColor]

          const leaseStatusMap = {
            0: "启用",
            1: "停用"
          }
          dataList.leaseStatus = leaseStatusMap[dataList.leaseStatus]

          const onlineShowMap = {
            0: "是",
            1: "否"
          }
          dataList.onlineShow = onlineShowMap[dataList.onlineShow]

          this.dataList = dataList;
        }
      });
    },

    //查询关联长租订单信息
    goSearch() {
      const autoId = this.query.autoId;
      const orderCode = this.query.orderCode;
      const leaseId = this.id;
      const pageSize = this.pageSize;
      const pageNo = this.pageNo;
      this.isLoading = true;
      getLeaseOrderListForPage(
          autoId,
          leaseId,
          pageSize,
          pageNo,
          orderCode
      ).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.total = res.returnObject.total;
          this.tableData = res.returnObject.records;
        }
      });
    },
    //点击审核弹出
    goVerify(e) {
      const id = e.id;
      getLeaseOrderById(id).then(res => {
        if (res && res.code === 30 && res.result) {
          this.examineList = res.returnObject;
        }
      })
    },
    //审核通过或拒绝
    leaseOrderVerify(e) {
      const id = this.examineList.id;
      const reason = this.refuseReason;
      const auditStatus = e;
      leaseOrderVerify(id, reason, auditStatus).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.refuseReason = "";
          this.dialogFormVisible = false;
          this.$message.success(res.message);
        }
      });
    },
    handleSizeChange() {
    },
    handleCurrentChange() {
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.parkingLotId = this.$route.query.parkingLotId;
    this.getLeaseInfoById();
    this.goSearch();
  },
};
</script>

<style lang="less" scoped>
.title {
  line-height: 32px;
}

.separate-line {
  margin: 40px -18px 25px -18px;
  height: 20px;
  background-color: #F4F7FC;
}

/deep/ .card .card-content {
  padding: 0;
}

.reason /deep/ .el-input__inner {
  width: 70%;
  display: block;
}

.total-amount {
  font-size: 18px;
  margin-top: 12px;
  margin-left: 50px;
  color: #000000;
}
</style>
